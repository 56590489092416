<template>
  <div class="dashboard">
    <transition name="slide-fade">
    <div v-if="!testerSelected" class="main">
      <div class="above-table">
       <Multiselect
          class="multiselect"
          style="width: 200px !important;"
          v-model="filterValue"
          :options="jobCodes"
          track-by="id"
          label="name"
          placeholder="Filter by Job Role"
          :show-labels="false"
          :searchable="true"
          :allow-empty="true"
        >{{filterValue}}
        </Multiselect>
        <div class="float-right">
          <Magnifier class="search-icn"></Magnifier>
          <input class="search" placeholder="Search testers" v-model="searchQuery" type="search"/>
           <Multiselect
          class="multiselect single rowCount"
          v-model="rowCountDropValue"
          :options="rowOptions"
          track-by="label"
          label="label"
          :showLabels="false"
          :allow-empty="false"
          :searchable="false"
        >{{filterValue}}</Multiselect>
        </div>
      </div>
      <div class="table-container" style='margin-bottom: 40px;'>
        <data-table
        :headings="testersTableHeaders"
        :rows="testers"
        :perPage="rowCountDropValue.value"
        :page="currentPage"
        tableName="testers"
        :clickableRows="true"
        :search-query="searchQuery"
        :jobFilter="jobFilter"
        >
        </data-table>
         <div class="row-container" v-if="testers.length > 0">
            <BasePagination
              :current-page="currentPage"
              :page-count="pageCount"
              class="articles-list__pagination"
              @nextPage="pageChangeHandle('next')"
              @previousPage="pageChangeHandle('previous')"
              @loadPage="pageChangeHandle"
              />
          </div>
      </div>
    </div>
    </transition>
    <transition name ="slide-fade">
    <div v-if="testerSelected && testerResults && timeToLoad" class="main">
      <div style="font-size: 12px; margin-bottom: 40px;">
        <span @click="removeSelectedTester()" style="cursor: pointer;">Testers</span> /
        <span style="font-family: VWText Bold; color: #00B0F0;">{{testerSelected.first_name}} {{testerSelected.last_name}}</span>
      </div>
      <div class="panel">
        <div class="panel-body">
            <div class="panel-row" style="width: 100%; justify-content: space-between; margin-bottom: 0;">
              <div style="display: flex; margin-right: 125px; align-items: center;" >
                <div style="font-size: 20px; white-space: nowrap;">{{testerSelected.first_name}} {{testerSelected.last_name}}</div>
             </div>
             <div class="panel-column">
              <span class="panel-label">Hub ID</span>
              <span class="panel-content">{{ testerSelected.hub_id }}</span>
            </div>
            <div class="panel-column">
              <span class="panel-label">Dealer Code</span>
              <span class="panel-content">{{ testerSelected.dealer_code}}</span>
            </div>
            <div class="panel-column">
              <span class="panel-label">Email</span>
              <span class="panel-content">{{ testerSelected.email }}</span>
            </div>
            <div class="panel-column">
              <span class="panel-label">Phone</span>
              <span class="panel-content">{{ testerSelected.phone }}</span>
            </div>
            <div class="panel-column">
              <span class="panel-label">Job Role</span>
              <span class="panel-content">{{ testerSelected.job_role_name }}</span>
            </div>
            </div>
        </div>
      </div>
      <div v-if="testerResults.length === 0">No assessment results have been recorded for this user.</div>
      <div class="panel" v-for="(assessment,index) in testerResults" :key="assessment.assessment_instance_id">
        <div class="panel-header">
          <div @click="toggleAssessment(index)">{{assessment.name}}</div>
          <carrot :class="assessment.open ? 'carrot' : 'carrot-flipped'" @click="assessment.open = !assessment.open; forceRefresh()"/>
        </div>
        <transition name="bounce">
        <div class="assessment" v-if="assessment.security && assessment.security.length > 0 && assessment.open">
        <div class="panel-body">
          <div class="panel-row" style="justify-content: space-between; width: 100%; border-bottom: 1px solid #DFE4E8; padding-bottom: 35px; margin-bottom: 25px;">
            <div style="display: flex;">
              <span>Completed: <span style="font-family: 'VWHead Bold'">{{ assessment.completion_date | date }}</span></span>
              <span style="margin: 0 10px; border-right: 1px solid #B6BFC5"></span>
              <span>Score: <span class="score">{{ assessment.correct_answers }} / {{ assessment.total_questions }} </span></span>
              <span style="margin: 0 10px; border-right: 1px solid #B6BFC5"></span>
              <div style="display: flex; align-content: center;">
                <span style="margin-right: 5px;">Suspicion Level:</span>
                <span v-if="!assessment.enabled_security_features" style="margin-right: 10px; color: #E4002C; font-family: 'VWHead Bold">0/0</span>
                <span v-if="assessment.enabled_security_features" style="margin-right: 10px; color: #E4002C; font-family: 'VWHead Bold">{{ assessment.flagged_security_features }} / {{ assessment.enabled_security_features }}</span>
                <div class="suspicion-rectangle">
                  <div class="inner-rectangle" :style="{ 'width': assessment.securityPercentage }"></div>
                </div>
              </div>
            </div>
            <div style="display: flex;">
              <button style="font-size: 14px; margin-right: 20px;" @click="downloadReport(assessment)">Download Results</button>
              <button style="font-size: 14px;" @click="allowRetake(assessment.assessment_id)">Allow Retake</button>
              </div>
          </div>
          <div class="panel-row" style="width: 100%; font-size: 12px; font-family: 'VWHead Bold'">Security Parameters included in this assessment:</div>
          <div class="panel-row security-parameters" style="margin-bottom: 35px;">
            <div class="panel-column">
              <div class="security-flex-top">
                <checkIcn  v-if="assessment.random_question_order" class="check-icn"></checkIcn>
                <xIcn v-if="!assessment.random_question_order" class="check-icn"></xIcn>
                <span>Randomized question order</span>
              </div>
              <div class="security-flex">
                <checkIcn v-if="assessment.security_questions" class="check-icn"></checkIcn>
                <xIcn v-if="!assessment.security_questions" class="check-icn"></xIcn>
                <span>Intermittent security questions</span>
                </div>
            </div>
            <div class="panel-column">
              <div class="security-flex-top">
                <checkIcn v-if="assessment.facial_recognition" class="check-icn"></checkIcn>
                <xIcn v-if="!assessment.facial_recognition" class="check-icn"></xIcn>
                <span>Periodic facial recognition</span>
                </div>
              <div class="security-flex">
                <checkIcn v-if="assessment.phone_email_auth" class="check-icn"></checkIcn>
                <xIcn v-if="!assessment.phone_email_auth" class="check-icn"></xIcn>
                <span>Email & phone number authentication</span>
                </div>
            </div>
             <div class="panel-column">
              <div class="security-flex-top">
                <checkIcn v-if="assessment.ip_monitoring" class="check-icn"></checkIcn>
                <xIcn v-if="!assessment.ip_monitoring" class="check-icn"></xIcn>
                <span>IP address monitoring</span>
                </div>
              <div class="security-flex">
                <checkIcn v-if="assessment.device_monitoring" class="check-icn"></checkIcn>
                <xIcn v-if="!assessment.device_monitoring" class="check-icn"></xIcn>
                <span>Device monitoring</span>
                </div>
            </div>
             <div class="panel-column">
              <div class="security-flex-top">
                <checkIcn v-if="assessment.wording_varients" class="check-icn"></checkIcn>
                <xIcn v-if="!assessment.wording_varients" class="check-icn"></xIcn>
                <span>Wording variants</span></div>
              <div class="security-flex"></div>
            </div>
          </div>
          <div v-if="assessment.security && assessment.security.length > 0" class="panel-row" style="width: 100%;">
            <div class="timeline-relative">
              <div class="timeline-rectangle"></div>
              <div v-for="item in assessment.security" :key="item.id" :class="['timeline-item', item.status, item.key]" :style="item.style">
                <div class="timeline-time">{{ item.timestamp | time }}</div>
                <div class="timeline-label">{{ item.label }}</div>
              </div>
            </div>
          </div>
            <div v-if="assessment.security && assessment.security.length === 0">No security flaws to report</div>
        </div>
        <data-table
          :headings="timelineTableHeaders"
          :rows="assessment.security"
          :perPage="100"
          :page="1"
          >
          </data-table>
          </div>
        </transition>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import DataTable from '@/components/DataTable.vue';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Magnifier from '@/assets/icons/magnifier.svg';
import carrot from '@/assets/icons/carrot_up.svg';
import checkIcn from '@/assets/icons/checkmark_icon.svg';
import xIcn from '@/assets/icons/x_icon_round.svg';
import BasePagination from '@/components/BasePagination.vue';

export default {
  name: 'testers',
  components: {
    DataTable,
    Magnifier,
    Multiselect,
    carrot,
    checkIcn,
    xIcn,
    BasePagination,
  },
  data() {
    return {
      testers: [],
      currentPage: 1,
      toggleColor: '#00B0F0',
      searchQuery: '',
      rowOptions: this.$store.state.rowOptions,
      filterOptions: this.$store.state.jobFilter,
      rowCountDropValue: { label: 'Show 25', value: 25 },
      filterValue: '',
      jobFilter: '',
      testerResults: [],
      timeToLoad: false,
    };
  },
  methods: {
    getTesters() {
      if (this.testers.length === 0) {
        const loader = this.$loading.show();
        this.$store.dispatch('getAllTesters').then((res) => {
          this.testers = res;
          if (this.testerSelected && !this.testerSelected.email) {
            const row = this.testers.find((tester) => {
              if (tester.id === this.testerSelected.user_id) {
                return tester;
              }
              return null;
            });
            if (row) {
              this.$store.commit('SET_SELECTED_TESTER', row);
            }
          }
          loader.hide();
        });
      }
    },
    removeSelectedTester() {
      this.timeToLoad = false;
      this.testerResults = null;
      this.getTesters();
      this.$store.commit('SET_SELECTED_TESTER', null);
    },
    toggleAssessment(index) {
      this.testers[index].open = !this.testers[index].open;
    },
    finishedAfterDeadline(endTime, completionTime) {
      const COMPLETION_TIME = moment(new Date(completionTime));

      function isBeforeDeadline(momentDate) {
        return momentDate.isBefore(COMPLETION_TIME);
      }
      const date = new Date(endTime);
      if (date) {
        if (isBeforeDeadline(moment(date))) {
          return true;
        }

        return false;
      }
      return endTime;
    },
    getTesterResults() {
      this.$store.dispatch('getUserResults', { userId: this.testerSelected.id })
        .then((res) => {
          if (res.length > 0) {
            this.testerResults = res;
            for (let i = 0; i < this.testerResults.length; i += 1) {
              if (this.testerResults[i].enabled_security_features) {
                this.testerResults[i].securityPercentage = `${Math.floor(this.testerResults[i].flagged_security_features / this.testerResults[i].enabled_security_features * 100).toString()}%`;
              }
              if (i === 0) {
                this.testerResults[i].open = true;
              } else {
                this.testerResults[i].open = false;
              }
              this.$store.dispatch('getSecurityFlags', { assessmentID: this.testerResults[i].assessment_instance_id })
                .then((res2) => {
                  if (res.length > 0) {
                    this.testerResults[i].security = JSON.parse(JSON.stringify(res2));
                    const startTime = new Date(res[i].start_time).getTime() / 1000;
                    let endTime = new Date(res[i].completion_date).getTime() / 1000;

                    if (endTime < startTime) {
                      endTime += '86400';
                    }

                    const timelineTime = Math.abs(endTime - startTime);

                    for (let j = 0; j < this.testerResults[i].security.length; j += 1) {
                      const time = new Date(this.testerResults[i].security[j].timestamp).getTime() / 1000;
                      const width = Math.floor(100 - ((endTime - time) / timelineTime * 100)).toString();
                      this.testerResults[i].security[j].style = {
                        left: `${width}%`,
                        'background-color': '#E4002C',
                      };
                    }
                    // eslint-disable-next-line no-param-reassign
                    this.testerResults[i].security.push({
                      description: 'Started Assessment',
                      timestamp: res[i].start_time,
                      style: {
                        left: '0%',
                        'background-color': '#DFE4E8',
                      },
                    });

                    // if (moment(this.res[i].end_date))
                    // eslint-disable-next-line no-param-reassign
                    this.testerResults[i].security.push({
                      description: 'Finished Assessment',
                      timestamp: res[i].completion_date,
                      style: {
                        left: '100%',
                        'background-color': '#E4002C',
                      },
                    });
                  } else {
                    this.testerResults[i].security = [
                    ];
                  }
                  this.timeToLoad = true;
                  this.forceRefresh();
                });
            }
          } else {
            this.timeToLoad = true;
          }
          this.getTesters();
        });
    },
    forceRefresh() {
      this.$forceUpdate();
    },
    allowRetake(assessmentID) {
      const loader = this.$loading.show();
      this.$store.dispatch('allowRetake', { assessmentID, userId: this.testerSelected.id })
        .then(() => {
          this.$notify({
            text: 'User may now retake assessment.',
            group: 'primary',
            type: 'success',
          });
          loader.hide();
        })
        .catch(() => {
          this.$notify({
            text: 'This assessment has already been selected to be retaken.',
            group: 'primary',
            type: 'error',
          });
          loader.hide();
        });
    },
    async pageChangeHandle(value) {
      switch (value) {
        case 'next':
          this.currentPage += 1;
          break;
        case 'previous':
          this.currentPage -= 1;
          break;
        default:
          this.currentPage = value;
      }
    },
    downloadReport(row) {
      const loader = this.$loading.show();
      this.$store.dispatch('downloadResult', { resultID: row.id })
        .then((res) => {
          this.downloadedReport = res;
          const hiddenElement = document.createElement('a');
          hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(res.data)}`;
          hiddenElement.target = '_blank';
          hiddenElement.download = `${row.name}.csv`;
          hiddenElement.click();
          loader.hide();
        });
    },
  },
  computed: {
    assessmentTrackingTableHeaders() {
      return this.$store.state.trackingHeaders;
    },
    testersTableHeaders() {
      return this.$store.state.testersTableHeaders;
    },
    testerSelected() {
      return this.$store.state.testerSelected;
    },
    timelineTableHeaders() {
      return [
        {
          label: 'Time',
          key: 'timestamp',
          filter: 'time',
          sortType: 'date',
          defaultSort: true,
        },
        {
          label: 'Event Description',
          key: 'description',
        },
      ];
    },
    jobCodes() {
      return this.$store.state.jobCodes;
    },
    pageCount() {
      const l = this.testers.length;
      const s = this.rowCountDropValue.value;
      return Math.ceil(l / s);
    },
  },
  watch: {
    searchQuery(val) {
      if (val) {
        this.currentPage = 1;
      }
    },
    filterValue(val) {
      if (val) {
        this.jobFilter = val.name;
      } else {
        this.jobFilter = '';
      }
    },
    testerSelected(val) {
      if (val) {
        this.getTesterResults();
      }
    },
    pageCount(val) {
      if (val === 1) {
        this.currentPage = 1;
      }
    },
  },
  created() {
    if (!this.testerSelected) {
      this.getTesters();
    } else {
      this.getTesterResults();
    }
  },
};
</script>

<style lang="scss" scoped>
.header-middle {
  display: flex;
}

.check-icn {
  margin-right: 10px;
}

.security-flex {
  display: flex;
}

.security-flex-top {
  display: flex;
  margin-bottom: 10px;
}

.score {
  font-family: 'VWHead Bold';
  color: #FFD100;
}

.table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
}

.table-container {
  font-size: 26px;
  color: black;
}

.above-table {
  display: flex;
  justify-content: space-between;
  color: black;
  margin-bottom: 25px;
}

.float-right {
  display: flex;
}

.panel {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  .panel-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .panel-body {
    display: flex;
    background-color: white;
    padding: 30px;
    box-shadow: 0px 2px 6px #0000001a;
    -webkit-appearance: none;
    align-items: center;
    flex-direction: column;
      .panel-row{
        display: flex;
        align-items: center;
      }
      .panel-column{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 50px;
        .panel-label{
          font-family: 'VWHead Bold';
          font-size: 14px;
          margin-bottom: 5px;
        }
        .panel-content{
          max-width: 165px;
          overflow: auto;
          text-overflow: ellipsis;
          font-size: 14px;
        }
      }
  }
  .security-parameters{
  margin-top: 10px;
  border-bottom: 1px solid #DFE4E8;
  padding-bottom: 25px;
  align-items: start !important;
  width: 100%;
  font-size: 12px;
  }
}
.suspicion-rectangle {
  align-self: center;
  width: 150px;
  background-color: #DFE4E8;
  height: 12px;
  margin-right: 10px;
  position: relative;
}
.inner-rectangle {
  background-color: #E4002C;
  height: 12px;
  position: absolute;
}
.timeline-relative {
  display: flex;
  position: relative;
  width: 100%;
  margin-right: 30px;
  margin-bottom: 40px;
}
.timeline-rectangle {
  width: 100%;
  background-color: #B6BFC5;
  height: 1px;
  position: relative;
}
.timeline-item {
  content: '';
    position: absolute;
    padding: 8px;
    border-radius: 50px;
    top: -8px;
}
.beginning {
    content: '';
    position: absolute;
    padding: 8px;
    border-radius: 50px;
    top: -8px;
}
.end {
    content: '';
    position: absolute;
    padding: 8px;
    border-radius: 50px;
    top: -8px;
}
.timeline-time {
  position: absolute;
  top: 25px;
  left: -10px;
  font-size: 10px;
}
.timeline-label {
  position: absolute;
  top: 40px;
  font-size: 10px;
  left: -8px;
  font-family: 'VWHead Bold'
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10%);
  opacity: 0;
}

// .bounce-enter-active {
//   animation: bounce-in .5s;
// }
// .bounce-leave-active {
//   animation: bounce-in .5s reverse;
// }
// @keyframes bounce-in {
//   0% {
//     transform: scaleY(0);
//     transform: margin-bottom 0;
//   }
//   100% {
//     transform: scaleY(1);
//     transform: margin-bottom 100%;
//   }
// }

</style>
